import React, { Suspense } from 'react';
import { Spin, Skeleton } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import './index.scss';
import modelNavs from './model';
class Nav extends React.Component {
	constructor ( props ){
		super();
		this.state = {
			active: 0,
		};
		this.props = props;
	}

	loadMoudle = ( index ) => {
		this.setState({ active: index });
	}

	render (){	
		const { active } = this.state;
		const { gl } = this.props;

		let menus = modelNavs;

		let ActiveModule;

		if( menus && menus.length ) {
			ActiveModule = menus[ active ].component;
		}

		return <div className="com-nav">
			<div className="nav-container">
				<div className="nav-header">
					{menus.map(( item, index ) => {
						return <div className={classnames({ "nav-item": true, active: active === index })} 
							title={item.text} 
							key={index}
							onClick={() => {
								this.loadMoudle( index );
							}}>
							<i className={ 'iconfont ' + item.icon }></i>
						</div>;
					})}
				</div>
				<div className="nav-content">
					{ gl && ActiveModule ?
						<Suspense fallback={<Spin indicator={LoadingOutlined} />}>
							<ActiveModule {...gl} loadModule={this.loadMoudle} />
						</Suspense>
					: <div className="loading">
						<Skeleton active />
						<Skeleton active />
						<Skeleton active />
						<Skeleton active />
						<Skeleton active />
						<Skeleton active />
					</div>}
				</div>
			</div>
		</div>
	}	
}

export default Nav;
