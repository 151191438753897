
export function GetXVersion ( generator ){
    let index = generator.indexOf('v');
    let version = generator.slice( index + 1 ).split('.');
    let MajorVersion = parseFloat( version[ 0 ] );
    let MinorVersion = parseFloat( version[ 1 ] );
    let Reversion = parseFloat( version[ 2 ] );
    return {
        MajorVersion,
        MinorVersion,
        Reversion
    }
}