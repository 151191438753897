import axios from 'axios';
import { host } from '../service';

const instance = axios.create({
    withCredentials: true,
    baseURL: host
})

export default {
    get ( url, options ){
        return instance.get( url, {
            params: options
        }).then(({ data }) => {
            return data;
        });
    },

    post ( url, options ) {
        return instance.post( url, options ).then(({ data }) => {
            return data;
        })
    }
}